<template>
  <div class="file-input" :class="{ have_file: url || isFile(file) }">
    <div class="file-input_label" v-if="label">{{ label }}:</div>

    <FormulateInput
      :key="url"
      class="file-input_form"
      type="file"
      :value="value"
      :disabled="!editable"
      v-bind:class="editableClass"
      :uploader="fakeUploader"
      @file-removed="fileRemoved"
      :validation="mimeValidation"
      :validation-messages="{
        mime: validationText
          ? validationText
          : labels.allowed_file_types(getMimeTypes),
      }"
    >
      <template v-slot:uploadAreaMask>
        <div class="image-input-extra">
          <span class="help-text">{{
            helpText ? helpText : labels.drag_and_drop_file_to_upload
          }}</span>
          <span class="phrase">{{
            separatorText ? separatorText : labels.or
          }}</span>
          <Button
            variant="btn-purple"
            :label="buttonText ? buttonText : labels.browse_files"
          />
        </div>
      </template>
    </FormulateInput>

    <CircleButton
      v-if="url"
      class="file-input_link"
      :color="`${colorsHtml.white}`"
      background_color="#14AAF5"
      icon="custom-external-link"
      width="30px"
      height="30px"
      font_size="16px"
      line_height="normal"
      margin="0px"
      linkTarget="_blank"
      :link="url"
    />
  </div>
</template>

<script>
import CircleButton from "../Buttons/CircleButton.vue";
import Button from "@/components/Buttons/Button";

import { library } from "@fortawesome/fontawesome-svg-core";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";

library.add(faExternalLinkAlt);
import colorsHtml from "/colors.config.json";
import { mapGetters } from "vuex";

export default {
  name: "FileInput",
  components: {
    CircleButton,
    Button,
  },
  props: {
    label: {
      type: String,
    },
    url: {
      type: String,
    },
    name: {
      type: String,
    },
    editable: {
      type: Boolean,
      default: true,
    },
    helpText: {
      type: String,
      default: "",
    },
    separatorText: {
      type: String,
      default: "",
    },
    buttonText: {
      type: String,
      default: "",
    },
    validationText: {
      type: String,
      default: "",
    },
    mimeTypes: {
      type: [String, Array],
      default: "",
    },
  },
  data() {
    return {
      colorsHtml: colorsHtml,
      file: "",
      // labels: {
      //     allowed_file_types: () => '',
      // },
    };
  },
  computed: {
    ...mapGetters("helper", ["labels"]),
    getMimeTypes() {
      let types = [];
      if (typeof this.mimeTypes === "string") {
        this.mimeTypes.split(",").forEach((item) => {
          types.push(item.split("/")[1]);
        });
      } else if (Array.isArray(this.mimeTypes)) {
        this.mimeTypes.forEach((item) => {
          types.push(item.split("/")[1]);
        });
      }
      return types.join(", ");
    },
    value() {
      if (this.url) {
        let arr = [{ url: this.url }];

        return arr;
      }

      return "";
    },
    editableClass() {
      if (!this.editable) {
        return "not-editable";
      }
      return "";
    },
    mimeValidation() {
      let output = "";
      if (typeof this.mimeTypes === "string" && this.mimeTypes) {
        output = `mime:${this.mimeTypes}`;
      } else if (Array.isArray(this.mimeTypes) && this.mimeTypes.length) {
        output = `mime:${this.mimeTypes.join(",")}`;
      }
      return output;
    },
  },
  methods: {
    isFile(obj) {
      return obj instanceof File;
    },
    fakeUploader(file) {
      this.file = file;
      this.fileChanged();

      return Promise.resolve({});
    },
    fileRemoved() {
      this.file = "";
      this.fileChanged();
    },
    fileChanged() {
      this.$emit("file-changed", this.file);
    },
  },
  mounted() {},
  watch: {},
};
</script>

<style lang="scss" scoped>
@import "@/styles/variables";

.file-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
  width: 100%;

  @media (min-width: 992px) {
    align-items: flex-start;
    flex-wrap: nowrap;
  }

  ::v-deep .not-editable {
    pointer-events: none;
  }

  ::v-deep .not-editable .formulate-file-remove {
    display: none;
  }

  &_label {
    font-size: 1.125rem;
    letter-spacing: 0.02em;
    text-align: left;
    margin-bottom: 15px;
    font-weight: 700;
    text-transform: inherit;
    order: 1;

    &::first-letter {
      text-transform: capitalize;
    }

    @media (min-width: 768px) {
      padding-right: 20px;
      max-width: 180px;
      flex: 0 0 20%;
      margin-top: 2.25rem;
      transform: translateY(-50%);
    }
  }

  ::v-deep {
    .formulate-input-element {
      display: flex;
    }

    .formulate-input-upload-area {
      padding: 0 0 0px !important;
      flex: 1 1;
      position: relative;

      .image-input-extra {
        background-color: $background;
        min-height: 67px;
        border-radius: 5px;
        display: flex;
        justify-content: center;
        align-items: center;
        flex-wrap: wrap;
        padding: 15px;

        .help-text {
          font-size: 1.063rem;
          font-weight: 700;
          display: flex;
          align-items: center;

          &::before {
            -webkit-mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 612.002 612.002" style="enable-background:new 0 0 612.002 612.002;" xml:space="preserve"><path d="M522.878,241.337c-16.836-56.94-69.525-98.491-131.923-98.491c-9.942,0-19.631,1.065-28.974,3.066 c-25.17-32.322-64.453-53.114-108.593-53.114c-75.972,0-137.563,61.59-137.563,137.565c0,0.946,0.017,1.889,0.036,2.83 C49.781,246.45,0,304.801,0,374.783c0,79.762,64.661,144.421,144.424,144.421h323.157c79.762,0,144.421-64.659,144.421-144.421 C612,314.612,575.201,263.043,522.878,241.337z M371.918,321.869c-4.381,4.382-10.125,6.574-15.869,6.574 c-5.744,0-11.486-2.191-15.869-6.572l-11.733-11.733v105.094c0,12.395-10.047,22.442-22.444,22.442 c-12.395,0-22.444-10.047-22.444-22.442V310.137l-11.734,11.734c-8.765,8.763-22.972,8.763-31.739-0.002 c-8.765-8.765-8.763-22.974,0-31.739l50.047-50.043c8.766-8.763,22.974-8.762,31.739,0l50.045,50.043 C380.681,298.894,380.683,313.105,371.918,321.869z"/></svg>');
            mask-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" x="0px" y="0px" viewBox="0 0 612.002 612.002" style="enable-background:new 0 0 612.002 612.002;" xml:space="preserve"><path d="M522.878,241.337c-16.836-56.94-69.525-98.491-131.923-98.491c-9.942,0-19.631,1.065-28.974,3.066 c-25.17-32.322-64.453-53.114-108.593-53.114c-75.972,0-137.563,61.59-137.563,137.565c0,0.946,0.017,1.889,0.036,2.83 C49.781,246.45,0,304.801,0,374.783c0,79.762,64.661,144.421,144.424,144.421h323.157c79.762,0,144.421-64.659,144.421-144.421 C612,314.612,575.201,263.043,522.878,241.337z M371.918,321.869c-4.381,4.382-10.125,6.574-15.869,6.574 c-5.744,0-11.486-2.191-15.869-6.572l-11.733-11.733v105.094c0,12.395-10.047,22.442-22.444,22.442 c-12.395,0-22.444-10.047-22.444-22.442V310.137l-11.734,11.734c-8.765,8.763-22.972,8.763-31.739-0.002 c-8.765-8.765-8.763-22.974,0-31.739l50.047-50.043c8.766-8.763,22.974-8.762,31.739,0l50.045,50.043 C380.681,298.894,380.683,313.105,371.918,321.869z"/></svg>');
            mask-repeat: no-repeat;
            background-color: #a8a8a8;
            content: "";
            margin-right: 15px;
            width: 32px;
            height: 32px;
            transition: all 300ms ease-out;
          }
        }

        .phrase {
          margin-left: 20px;
          margin-right: 20px;
          font-weight: 600;
          font-size: 1rem;
          color: #bbbbbb;
        }
      }

      &:hover {
        .image-input-extra {
          .help-text {
            &::before {
              background-color: $navy;
            }
          }
        }
      }

      &[data-has-files="true"] {
        .image-input-extra {
          display: none;
        }

        .formulate-files {
          width: 100%;
        }
      }
    }
  }

  &_form {
    min-width: 110px;
    margin-bottom: 0;
    flex: 0 0 100%;
    max-width: 100%;
    width: 100%;
    order: 3;

    @media (min-width: 768px) {
      order: 2;
      flex: 1 1 auto;
      width: auto;
      margin-right: 15px;
    }
  }

  &_link {
    order: 2;
    margin-bottom: 15px;

    @media (min-width: 768px) {
      margin-bottom: 0;
      order: 3;
      margin-top: 2.25rem;
      transform: translateY(-50%);
    }
  }
}
</style>
